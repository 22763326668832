class ApiError extends Error {
  constructor(axiosError = {}, ...params) {
    super(...params)

    this.name = 'ApiError'

    if (!axiosError.response) {
      this.message = axiosError.message
      return
    }

    this.message = axiosError.response.data.error
    this.status = axiosError.response.status
  }
}

export default ApiError
